import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import React from 'react'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Heading from 'components/Luxkit/Typography/Heading'

interface Props {
  title: string
  description: string
  itemNumber: number
}

function AgentHubOrderedSellingPointsTile(props: Props) {
  const { itemNumber, title, description } = props

  return <Group direction="horizontal" gap={16}>
    <Heading variant="heading3"><i>{itemNumber}.</i></Heading>
    <Group direction="vertical" gap={4}>
      <BodyText variant="medium" weight="bold">{title}</BodyText>
      <BodyTextBlock variant="medium">
        {description}
      </BodyTextBlock>
    </Group>
  </Group>
}

export default AgentHubOrderedSellingPointsTile
